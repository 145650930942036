.svg-inline--fa {
  &.icon-green {
    color: $green-400;
  }

  &.icon-lightgreen {
    color: $green-300;
  }

  &.icon-yellow {
    color: $orange-100;
  }

  &.icon-navy {
    color: $blue-100;
  }

  &.icon-blue {
    color: $blue-200;
  }

  &.icon-vivid-blue {
    color: $blue-500;
  }

  &.icon-orange {
    color: $orange-200;
  }

  &.icon-red {
    color: $red-400;
  }

  &.icon-coral {
    color: $red-500;
  }

  &.icon-brown {
    color: $orange-300;
  }

  &.icon-gray {
    color: $gray-800;
  }
}
