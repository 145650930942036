footer {
  background-color: $gray-900;
  color: $gray-700;
  overflow: hidden;
  height: 7.5rem;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  padding-left: 1.5rem;

  .link {
    display: contents;
    color: $gray-700;
    &:hover {
      text-decoration: underline;
    }
  }
}
