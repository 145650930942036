/*
--------------------------------------------------------------------------------
COLORS
--------------------------------------------------------------------------------
*/
$black:        #000000;
$blue-100:     #97a6fe; // Icons
$blue-200:     #17a2b8; // Icons
$blue-300:     #007bff; // Links
$blue-400:     #0056b3; // Links - hover
$blue-500:     #0000FF; // Icons
$gray-100:     #f7f8f9; // Calculator Background
$gray-200:     #e6e7e9; // Default Button Background - hover
$gray-400:     #dee2e6; // Tabs Border - hover
$gray-500:     #cccccc; // Default Button and Tabs Border
$gray-600:     #b0b0b0; // Close Modal Button, Input Info Text, Input Border
$gray-700:     #999999; // Footer and Header Links
$gray-800:     #808080; // Input Text and Default Button Text - hover
$gray-900:     #333333; // Footer and Header Links - hover
$green-100:    #d4edda; // Default Message Background
$green-200:    #c3e6cb; // Default Message Border
$green-300:    #7b9e35; // Icons and <h2>
$green-400:    #28a745; // Icons and Submit Button Background
$green-500:    #218838; // Submit Button Background - hover
$green-600:    #155724; // Default Message Text
$orange-100:   #ffc107; // Icons
$orange-200:   #ffaa50; // Icons
$orange-300:   #d67e26; // Icons
$red-100:      #f8d7da; // Error Message Background
$red-200:      #f5c6cb; // Error Message Border
$red-300:      #721c24; // Error Message Text
$red-400:      #ff4e1f; // Icons
$red-500:      #ff7f50; // Icons
$yellow-100:   #ffea99; // Warning Message Background
$yellow-200:   #ffe57f; // Warning Message Border
$yellow-300:   #997a00; // Warning Message Text
$white:        #FFFFFF;
