.my-progress {
  padding-top: 1rem;

  .progress-table {
    padding-top: 2rem;

    .progress-table-header {
      display: flex;
      width: 100%;

      .header-column {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        svg {
          font-size: 3rem;
        }

        .title {
          font-size: 1.5rem;
        }
      }
    }

    .progress-table-rows {
      min-height: 25rem;
      max-height: 35rem;
      overflow: scroll;

      .progress-table-row {
        .row-header {
          margin-top: 0.5rem;
          background-color: $gray-400;
          border-top: 0.1rem solid $gray-700;
          border-right: 0.1rem solid $gray-700;
          border-left: 0.1rem solid $gray-700;
          font-size: 1.5rem;
          padding: 0.5rem 1.5rem;
          display: flex;
          justify-content: space-between;
        }

        .row-data {
          border-bottom: 0.1rem solid $gray-700;
          border-right: 0.1rem solid $gray-700;
          border-left: 0.1rem solid $gray-700;
          display: flex;
          align-items: center;

          div {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 0;
            font-weight: 600;
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            padding: 0.5rem 0;

            &.red {
              background-color: $red-500;
            }

            &.navy {
              background-color: $blue-100;
            }

            &.yellow {
              background-color: $orange-100;
            }

            &.brown {
              background-color: $orange-300;
            }
          }
        }
      }
    }
  }

  .progress-totals-details {
    padding-top: 2rem;

    h3 {
      font-size: 1.8rem;
    }
  }
}
