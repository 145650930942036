.custom-output {
  color: $gray-800;

  &.big {
    color: $black;
    font-size: 1.8rem;
    margin-top: 0.5rem;
  }

  .icon {
    display: inline-block;
    width: 3rem;
  }

  .value {
    margin: 0 0.5rem;
    font-weight: 600;
  }

  .units {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .output-title {
    font-weight: 600;
  }
}
