/*
--------------------------------------------------------------------------------
Mobile first queries
--------------------------------------------------------------------------------
*/
@mixin phablet {
  @media (min-width: 550px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 800px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1000px) {
    @content;
  }
}
@mixin hd {
  @media (min-width: 1400px) {
    @content;
  }
}
@mixin resolution($r) {
  @media (min-width: #{$r}) {
    @content;
  }
}
