/*
--------------------------------------------------------------------------------
HTML elements look n feel
--------------------------------------------------------------------------------
*/
body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  background-color: $white;
  color: $gray-900;
  font-size: 1.6rem;
}

h2 {
  font-size: 3rem;
  color: $green-300;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

ul {
  li {
    margin: 0.8rem 0 0 3.5rem;
    list-style-type: disc;
  }
}

hr {
  height: 0.1rem;
  border: none;
  background-color: $gray-200;
  margin: 1rem 0;
}

ol {
  counter-reset: ol_counter;
  li {
    counter-increment: ol_counter;
    position: relative;
    margin: 1rem 0 0 3.5rem;
    &::before {
      position: absolute;
      left: -1.5rem;
      content: counter(ol_counter) ".";
    }
  }
}

.label-section {
  display: flex;
  align-items: center;

  .label-info {
    margin-left: 0.5rem;
    color: $gray-600;
    font-size: 1.4rem;
  }
}

.container {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;

  @include phablet {
    max-width: 54rem;
  }

  @include tablet {
    max-width: 80rem;
  }

  @include desktop {
    max-width: 120rem;
  }
}

.version {
  float: right;
}

/*
--------------------------------------------------------------------------------
Fonts
--------------------------------------------------------------------------------
*/

.strong {
  font-weight: 600;
}

.small {
  font-size: 1.3rem;
}

/*
--------------------------------------------------------------------------------
Spacing
--------------------------------------------------------------------------------
*/

.mt-5 {
  margin-top: 0.5rem;
}
.mt-10 {
  margin-top: 1rem;
}
.mt-15 {
  margin-top: 1.5rem;
}
.mr-5 {
  margin-right: 0.5rem;
}
.mr-10 {
  margin-right: 1rem;
}
.mr-15 {
  margin-right: 1.5rem;
}
.mb-5 {
  margin-bottom: 0.5rem;
}
.mb-10 {
  margin-bottom: 1rem;
}
.mb-15 {
  margin-bottom: 1.5rem;
}
.ml-5 {
  margin-left: 0.5rem;
}
.ml-10 {
  margin-left: 1rem;
}
.ml-15 {
  margin-left: 1.5rem;
}
