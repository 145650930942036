.message {
  width: 100%;
  color: $green-600;
  background-color: $green-100;
  border: 0.1rem solid $green-200;
  padding: 1.2rem 2rem;
  border-radius: 0.3rem;
  margin-top: 0;
  font-size: 1.6rem;
  line-height: 1.5;

  &.error {
    color: $red-300;
    background-color: $red-100;
    border: 0.1rem solid $red-200;
  }

  &.warning {
    background-color: $yellow-100;
    border: 0.1rem solid $yellow-200;
    color: $yellow-300;
  }
}
