.log-details {
  h3, div {
    margin-bottom: 0.5rem;
  }

  div {
    color: $black;

    .icon {
      width: 2rem;
    }
  }
}
