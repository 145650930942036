a {
  color: $blue-300;
  text-decoration: none;
  font-size: 1.28rem;

  &:hover {
    text-decoration: underline;
    color: $blue-400;
  }

  &.footer-link {
    font-size: 1.6rem;
    display: contents;
    color: $gray-700;
  }

  &.header-link {
    padding: 1rem 0.5rem;
    color: $gray-700;
    &:hover {
      color: $gray-900;
      text-decoration: none;
    }
  }

  &.btn {
    background-color: $white;
    border: 0.1rem solid $gray-500;
    border-radius: 0.3rem;
    padding: 1rem 1.6rem;
    font-size: 1.4rem;
    outline: none;
    display: flex;
    align-items: center;
    color: $black;

    .text {
      margin-left: 1rem;
      font-size: 2rem;
    }

    &:hover {
      background-color: $gray-200;
      text-decoration: none;
      cursor: pointer;
    }
  }
}
