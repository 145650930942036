.custom-select {
  max-width: 30rem;
  margin-bottom: 2rem;

  select {
    border-radius: 0.4rem;
    background-color: $white;
    font-size: 1.6rem;
    outline: 0;
    min-width: 5rem;

    &.large {
      width: 100%;
    }

    &.single-select {
      height: 3rem;
    }

    &.multiple-select {
      width: 22rem;
      padding: 1rem;
    }
  }

  &.inline {
    display: flex;
    flex-wrap: wrap;
    font-size: 1.6rem;

    select {
      margin-left: 0.5rem;
      min-width: 10rem;
    }
  }
}
