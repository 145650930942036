.info {
  padding: 3rem 0 8rem;

  h3 {
    margin-top: 1.5rem;
  }

  p {
    margin-top: 2rem;
  }

  h3:not(:first-of-type) {
    margin-top: 3rem;
  }
}
