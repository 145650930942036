header {
  .logo-section {
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 9rem;
    padding-left: 1.5rem;

    .logo {
      margin-right: 2rem;
    }
  }
}
