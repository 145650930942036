button {
  background-color: $white;
  border: 0.1rem solid $gray-500;
  border-radius: 0.3rem;
  padding: 0.4rem 0.8rem;
  font-size: 1.4rem;
  outline: none;

  &:hover {
    background-color: $gray-200;
    cursor: pointer;
  }

  &.tab {
    background-color: transparent;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 0.1rem solid transparent;
    display: inline-block;
    padding: 0.8rem 1.6rem;
    box-sizing: border-box;
    font-size: 1.6rem;
    margin-bottom: -0.1rem;
    margin-right: 0;
    border-bottom: 0.1rem solid $gray-500;

    &:hover {
      cursor: pointer;
      border-top: 0.1rem solid $gray-400;
      border-left: 0.1rem solid $gray-400;
      border-right: 0.1rem solid $gray-400;
      color: $blue-400;
    }

    &.selected {
      border: 0.1rem solid $gray-500;
      background-color: $white;
      border-bottom: 0.1rem solid $white;
      color: $black;
    }

    .text {
      margin-left: 0.3rem;
    }

    svg {
      margin-right: 0.2rem;
    }
  }

  &.large {
    width: 100%;
    padding: 1rem;
    font-size: 2rem;
    line-height: 1.5;
  }

  &.submit {
    background-color: $green-400;
    color: $white;
    border-radius: 0.4rem;

    &:hover {
      background-color: $green-500;
    }
  }

  &.link {
    border: 0;
    color: $blue-300;
    text-decoration: none;
    font-size: 1.28rem;

    &:hover {
      color: $blue-400;
      background-color: $white;

      .text {
        text-decoration: underline;
      }
    }
  }

  &.activity-log-close {
    background-color: transparent;
    color: $gray-700;
    font-size: 2.4rem;
    font-weight: 600;
    outline: none;
    border: 0;

    .text {
      margin-right: 0.5rem;
    }

    &:hover {
      color: $gray-800;
      cursor: pointer;
    }
  }

  &.modal-close {
    border: 0;
    align-self: flex-end;
    font-size: 2rem;
    color: $gray-600;

    &:hover {
      color: $gray-800;
      background-color: $white;
      cursor: pointer;
    }
  }
}
