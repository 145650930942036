.progress-viewer {
  .options-container {
    max-height: 0;
    transition: 0.5s;
    overflow: hidden;

    &.show {
      max-height: 30rem;
    }

    .fitbit-options {
      font-size: 1.6rem;
      padding: 2rem;
      color: $gray-800;
      background-color: $gray-100;
      border: 0.1rem solid $gray-500;
      border-radius: 0.3rem;

      .title {
        font-weight: 600;
      }

      .custom-select {
        margin-bottom: 0;

        select {
          padding: 0.1rem;
        }
      }
    }
  }
}
