.calculator {
  background-color: $gray-100;
  border-radius: 0.8rem;
  border: 0.1rem solid $gray-200;
  height: 100%;
  padding: 0.5rem 1rem 1rem;
  display: flex;
  flex-direction: column;

  h3 {
    margin-bottom: 1.5rem;
  }

  .input-area {
    background-color: $white;
    border-radius: 0.8rem;
    border: 0.1rem solid $gray-500;
    padding: 2rem;
    font-size: 2rem;

    h3 {
      margin-top: 1rem;
    }

    hr {
      height: 0.1rem;
      border: none;
      background-color: $gray-200;
    }

    .my-account {
      font-size: 1.6rem;

      .logged-out-info {
        margin-top: 2rem;
      }
    }

    .custom-input {
      margin-bottom: 2rem;

      input {
        border: 0.1rem solid $gray-600;
        border-radius: 0.4rem;
        font-size: 1.6rem;
        padding: 0.5rem 0.8rem;
        outline: 0;
        min-width: 5rem;
      }
    }
  }

  a {
    align-self: flex-end;
  }

  section {
    box-sizing: border-box;
    max-width: 100%;
  }
}
