main {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  section {
    flex-basis: 0;
    max-width: 51rem;
    flex-grow: 1;
    min-width: 48rem;
    max-width: 51rem;
    padding: 0 1.5rem 0;

    @include tablet {
      min-width: 36rem;
      max-width: 100%;
    }
  }

}
