.calorie-calculator {
  margin-top: 3rem;

  .input-area {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-color: $white;
  }

  .help-section {
    font-size: 1.6rem;

    h3 {
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }

    img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
      max-width: 100%;
    }
  }
}
