.fitbit-section {
  margin-top: 3rem;

  .fitbit-info {
    display: flex;
    align-items: center;
    overflow: hidden;

    &.column {
      flex-direction: column;
      align-items: flex-start;

      .fitbit-button {
        align-self: flex-start;
      }
    }
    .fitbit-account {
      display: flex;
      flex-direction: column;
      width: 100%;

      .disconnect-button {
        align-self: flex-start;
        max-width: 10rem;
      }
    }
  }
}
