.activity-log {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .activity-log-close {
    align-self: flex-end;
  }

  .results {
    font-size: 1.6rem;
  }
}
