.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z1;
  background: rgba(0, 0, 0, 0.6);
  display: none;
  overflow: scroll;

  &.show {
    display: block;
  }

  .custom-modal {
    z-index: $z2;
    max-width: 50rem;
    margin: 3rem auto 3rem;
    background-color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 0.4rem;

    .header {
      display: flex;
      flex-direction: column;

      padding: 1.6rem;
      border-bottom: 0.1rem solid $gray-500;
      font-weight: 600;
    }

    .body {
      padding: 1.6rem;
      font-size: 1.6rem;
      display: flex;
      flex-direction: column;

      .messaging {
        padding: 1rem;
        border-radius: 0.4rem;

        &.ok {
          color: $green-600;
          background-color: $green-100;
          border: 0.1rem solid $green-200;
        }

        &.warning {
          color: $yellow-300;
          background-color: $yellow-100;
          border: 0.1rem solid $yellow-200;
        }

        &.error {
          color: $red-300;
          background-color: $red-100;
          border: 0.1rem solid $red-200;
        }
      }

      input {
        width: 100%;
        line-height: 1.5;
        border-radius: 0.4rem;
        padding: 0.6rem 1.2rem;
        border: 0.1rem solid $gray-500;
        margin: 0.5rem 0 1rem;
      }

      .input-info {
        color: $gray-600;
        margin-top: -0.5rem;
      }

      hr {
        background-color: $gray-500;
        margin: 1.5rem 0 2rem;
      }

      .links {
        padding-top: 2rem;
      }
    }
  }

  .log-editor-form {
    p {
      margin-bottom: 2rem;
    }

    h3 {
      margin-bottom: 0.5rem;
    }
  }
}
